import type { TFilter } from '@tl-ui/components/form/FilterGroup.vue'
import type { TJobSearchFiltersCount } from '@tl-lms/types/job'

// Plain value options (before translations)
// Don't include the blank / all / * options here as they are added in the component
const roles = [
  'SOFTWARE_ENGINEER',
  'DATA_SCIENCE',
  'UXUI_DESIGNER',
  'PRODUCT_PROJECT_MANAGER',
  'BUSINESS_MANAGEMENT',
  'DIGITAL_MARKETING',
  'CYBERSECURITY',
  'SALES_ACCOUNT_MANAGEMENT',
  'CONSULTING_SYSTEM_INTEGRATION',
  'IT_SUPPORT',
  'NETWORKING_SYSTEM_ADMIN',
  'GRAPHIC_DESIGN',
  'MULTIMEDIA',
  'TECHNICAL_WRITING',
  'OTHERS'
]

const Languages = [
  'English',
  'Mandarin',
  'Cantonese',
  'Malay',
  'Japanese',
  'Korean',
  'Arabic',
  'French',
  'Tamil'
]

const HKVisaStatues = ['NOT_SPECIFIED', 'NO_NEED', 'NEED_A_PERMIT']
const MYVisaStatues = ['NOT_SPECIFIED', 'NO_NEED', 'NEED_A_PERMIT']

const ISOGeoCodes = ['HK', 'MY', 'CN', 'ID', 'PH', ]

const jobUserTags = ['KYDP 2024 (A)', 'KYDP 05-2024 (A)', 'KYDP 06-2024 (A)']

const HKlocations = [
  'Eastern|Hong Kong',
  'Central and Western|Hong Kong',
  'Southern|Hong Kong',
  'Sham Shui Po|Hong Kong',
  'Kowloon City|Hong Kong',
  'Wong Tai Sin|Hong Kong',
  'Kwun Tong|Hong Kong',
  'Yau Tsim Mong|Hong Kong',
  'Kwai Tsing|Hong Kong',
  'Tsuen Wan|Hong Kong',
  'Tuen Mun|Hong Kong',
  'Yuen Long|Hong Kong',
  'North|Hong Kong',
  'Tai Po|Hong Kong',
  'Sha Tin|Hong Kong',
  'Sai Kung|Hong Kong',
  'Islands|Hong Kong',
  'Wan Chai|Hong Kong'
]

const MYlocations = [
  'Johor|Malaysia',
  'Kedah|Malaysia',
  'Kelantan|Malaysia',
  'Malacca|Malaysia',
  'Negeri Sembilan|Malaysia',
  'Pahang|Malaysia',
  'Penang|Malaysia',
  'George Town|Penang|Malaysia',
  'Butterworth|Penang|Malaysia',
  'Perai|Penang|Malaysia',
  'Bukit Mertajam|Penang|Malaysia',
  'Bayan Lepas|Penang|Malaysia',
  'Perak|Malaysia',
  'Perlis|Malaysia',
  'Sabah|Malaysia',
  'Sarawak|Malaysia',
  'Selangor|Malaysia',
  'Ampang Jaya|Selangor|Malaysia',
  'Cheras|Selangor|Malaysia',
  'Cyberjaya|Selangor|Malaysia',
  'Damansara|Selangor|Malaysia',
  'Kajang|Selangor|Malaysia',
  'Klang|Selangor|Malaysia',
  'Petaling Jaya|Selangor|Malaysia',
  'Puchong|Selangor|Malaysia',
  'Puncak Alam|Selangor|Malaysia',
  'Shah Alam|Selangor|Malaysia',
  'Subang Jaya|Selangor|Malaysia',
  'Sungai Buloh|Selangor|Malaysia',
  'Terengganu|Malaysia',
  'Kuala Lumpur|Malaysia',
  'Labuan|Malaysia',
  'Putrajaya|Malaysia'
]

const industries = [
  'Accommodation Services',
  'Administrative and Support Services',
  'Construction',
  'Consumer Services',
  'Education',
  'Entertainment Providers',
  'Farming, Ranching, Forestry',
  'Financial Services',
  'Government Administration',
  'Holding Companies',
  'Hospitals and Health Care',
  'Information Technology and Services',
  'Manufacturing',
  'Oil, Gas, and Mining',
  'Professional Services',
  'Real Estate and Equipment Rental Services',
  'Retail',
  'Technology, Information and Media',
  'Transportation, Logistics, Supply Chain and Storage',
  'Utilities',
  'Wholesale'
]

const positionTypes = ['FULL_TIME', 'PART_TIME', 'CONTRACT', 'INTERNSHIP']

const workTypes = ['PHYSICAL', 'REMOTE', 'HYBRID']

const companySizes = [
  '1-10',
  '11-50',
  '51-200',
  '201-500',
  '501-1000',
  '1001-5000',
  '5001-10000',
  '10001+'
]

const companyLeadSources = ['SELF_ONBOARDED', 'BD_CREATED', 'SCRAPED']

const companyActiveStatus = ['ACTIVE', 'INACTIVE']

const campaignActiveStatus = ['ACTIVE', 'INACTIVE']

const currencies = ['HKD', 'MYR']

const salaries = {
  HKD: [
    10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 110000, 120000, 130000,
    140000, 150000, 160000, 170000, 180000, 190000, 200000
  ],
  MYR: [
    1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000,
    16000, 17000, 18000, 19000, 20000
  ]
}

const jobAppReadStatus = ['READ', 'UNREAD']
const jobAppDownloadedStatus = ['DOWNLOADED', 'NOT_DOWNLOADED']

const yearsOfExperiences = ['0', '1-2', '3-5', '6-10', '10-19', '20+']
const educationLevels = [
  'BELOW_HIGH_SCHOOL',
  'HIGH_SCHOOL',
  'DIPLOMA_OR_HIGHER_DIPLOMA',
  'DEGREE',
  'MASTER_OR_ABOVE'
]

const employmentStatusOptions = [
  'EMPLOYED_AND_NOT_LOOKING_FOR_JOB',
  'EMPLOYED_BUT_LOOKING_FOR_JOB',
  'UNEMPLOYED_AND_LOOKING_FOR_JOB',
  'UNEMPLOYED_BUT_NOT_LOOKING_FOR_JOB'
]

const hearUsOptions = ['Linkedin', 'Instagram', 'Email', 'Event Leaflet']

// Options for filters (add translations as label)
function getEmploymentStatusOptions(t: any, includeAllOption: boolean = false) {
  const options = employmentStatusOptions.map((status: string) => ({
    label: t(`jobBoard.filter.employmentStatus.option.${status}`),
    value: status
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.employmentStatus.placeholder'),
      value: '*'
    })
  }
  return options
}

function getISOGeoCodes(t: any, includeAllOption: boolean = false) {
  const options = ISOGeoCodes.map((ISOGeoCode: string) => ({
    label: t(`jobBoard.filter.isoGeoCode.option.${ISOGeoCode}`),
    value: ISOGeoCode
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.isoGeoCode.placeholder'),
      value: '*'
    })
  }
  return options
}

function getJobUserTags(t: any, includeAllOption: boolean = false) {
  const options = jobUserTags.map((jobUserTag: string) => ({
    label: t(`jobBoard.filter.jobUserTag.option.${jobUserTag}`),
    value: jobUserTag
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.jobUserTag.placeholder'),
      value: '*'
    })
  }
  return options
}

function getYearsOfExperiencesOptions(t: any, includeAllOption: boolean = false) {
  const options = yearsOfExperiences.map((yearsOfExperience: string) => ({
    label: t(`jobBoard.filter.yearsOfExperience.option.${yearsOfExperience}`),
    value: yearsOfExperience
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.yearsOfExperience.placeholder'),
      value: '*'
    })
  }
  return options
}

function getEducationLevelOptions(t: any, includeAllOption: boolean = false) {
  const options = educationLevels.map((educationLevel: string) => ({
    label: t(`jobBoard.filter.educationLevel.option.${educationLevel}`),
    value: educationLevel
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.educationLevel.placeholder'),
      value: '*'
    })
  }
  return options
}

function getRoleOptions(t: any, includeAllOption: boolean = false) {
  const options = roles.map((role: string) => ({
    label: t(`jobBoard.filter.role.option.${role}`),
    value: role
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.role.placeholder'),
      value: '*'
    })
  }
  return options
}

function getPositionTypeOptions(t: any, includeAllOption: boolean = false) {
  const options = positionTypes.map((positionType: string) => ({
    label: t(`jobBoard.filter.positionType.option.${positionType}`),
    value: positionType
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.positionType.placeholder'),
      value: '*'
    })
  }
  return options
}

function getWorkTypeOptions(t: any, includeAllOption: boolean = false) {
  const options = workTypes.map((workType: string) => ({
    label: t(`jobBoard.filter.workType.option.${workType}`),
    value: workType
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.workType.placeholder'),
      value: '*'
    })
  }
  return options
}

function getCompanySizeOptions() {
  return companySizes.map((companySize: string) => ({
    label: companySize,
    value: companySize
  }))
}

function getIndustryOptions(t: any, includeAllOption: boolean = false) {
  const options = industries.map((industry: string) => ({
    label: t(`jobBoard.filter.industry.option.${industry}`),
    value: industry
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.industry.placeholder'),
      value: '*'
    })
  }
  return options
}

function getCampaignFilterOptions(t: any, includeAllOption: boolean = false) {
  const workTypesOption = workTypes.map((workType: string) => ({
    label: t(`jobBoard.filter.workType.option.${workType}`),
    value: workType
  }))
  const positionTypesOption = positionTypes.map((positionType: string) => ({
    label: t(`jobBoard.filter.positionType.option.${positionType}`),
    value: positionType
  }))
  const getCampaignFilter = workTypesOption.concat(positionTypesOption)
  if (includeAllOption) {
    getCampaignFilter.unshift({
      label: t('jobBoard.filter.allSelected'),
      value: '*'
    })
  }
  return getCampaignFilter
}

function getAppLanguageOptions(t: any, includeAllOption: boolean = false) {
  const language = Languages
  const options = language.map((language: string) => ({
    label: t(`jobBoard.filter.languages.option.${language}`),
    value: language
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.languages.placeholder'),
      value: '*'
    })
  }
  return options
}

function getVisaStatusOptions(t: any, includeAllOption: boolean = false) {
  const regionState = useRegion()
  let visaStatus
  if (regionState.value === 'HK') {
    visaStatus = HKVisaStatues
  } else {
    visaStatus = MYVisaStatues
  }
  const options = visaStatus.map((visaStatus: string) => ({
    label: t(`jobBoard.filter.visaStatus.option.${visaStatus}`),
    value: visaStatus
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.visaStatus.placeholder'),
      value: '*'
    })
  }
  return options
}

function getLocationOptions(t: any, includeAllOption: boolean = false) {
  const regionState = useRegion()
  let location
  if (regionState.value === 'HK') {
    location = HKlocations
  } else {
    location = MYlocations
  }
  const options = location.map((location: string) => {
    const locationParts = location.split('|')
    // The last part is the region
    const region = locationParts.pop()
    // The rest is the area
    const translatedLocationParts = locationParts.map((locationPart: string) =>
      t(`jobBoard.filter.location.area.option.${locationPart}`)
    )
    const areaDisplay = translatedLocationParts.join(', ')
    const regionDisplay = t(`jobBoard.filter.location.region.option.${region}`)
    return {
      label: `${areaDisplay}, ${regionDisplay}`,
      value: location
    }
  })
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.location.placeholder'),
      value: '*'
    })
  }
  return options
}

function getCompanyLeadSourceOptions(t: any, includeAllOption: boolean = false) {
  const options = companyLeadSources.map((companyLeadSource: string) => ({
    label: t(`jobBoard.filter.companyLeadSource.option.${companyLeadSource}`),
    value: companyLeadSource
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.companyLeadSource.placeholder'),
      value: '*'
    })
  }
  return options
}

function getCompanyActiveStatusOptions(t: any, includeAllOption: boolean = false) {
  const options = companyActiveStatus.map((companyActiveStatus: string) => ({
    label: t(`jobBoard.filter.companyActiveStatus.option.${companyActiveStatus}`),
    value: companyActiveStatus
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.companyActiveStatus.placeholder'),
      value: '*'
    })
  }
  return options
}

function getCampaignActiveStatusOptions(t: any, includeAllOption: boolean = false) {
  const options = campaignActiveStatus.map((campaignActiveStatus: string) => ({
    label: t(`jobBoard.filter.campaignActiveStatus.option.${campaignActiveStatus}`),
    value: campaignActiveStatus
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.campaignActiveStatus.placeholder'),
      value: '*'
    })
  }
  return options
}

function getSalaryOptions(currency: 'HKD' | 'MYR', t: any, includeAllOption: boolean = false) {
  if (!salaries[currency]) {
    return []
  }
  const options = salaries[currency].map((salary: number) => ({
    label: salary,
    value: String(salary)
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.salary.placeholder'),
      value: '*'
    })
  }
  return options
}

function getCurrencyOptions(t: any, includeAllOption: boolean = false) {
  const options = currencies.map((currency: string) => ({
    label: currency,
    value: currency
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.salaryCurrency.placeholder'),
      value: '*'
    })
  }
  return options
}

function getJobAppReadStatusOptions(t: any, includeAllOption: boolean = false) {
  const options = jobAppReadStatus.map((jobAppReadStatus: string) => ({
    label: t(`jobBoard.filter.jobAppReadStatus.option.${jobAppReadStatus}`),
    value: jobAppReadStatus
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.jobAppReadStatus.placeholder'),
      value: '*'
    })
  }
  return options
}

function getJobAppDownloadedStatusOptions(t: any, includeAllOption: boolean = false) {
  const options = jobAppDownloadedStatus.map((jobAppDownloadedStatus: string) => ({
    label: t(`jobBoard.filter.jobAppDownloadedStatus.option.${jobAppDownloadedStatus}`),
    value: jobAppDownloadedStatus
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.jobAppDownloadedStatus.placeholder'),
      value: '*'
    })
  }
  return options
}

function getHearUsOptions(t: any, includeAllOption: boolean = false) {
  return [
    ...(includeAllOption ? [{ value: '*', label: t('all') }] : []),
    ...hearUsOptions.map((option) => ({
      value: option,
      label: t(option)
    }))
  ]
}

// Configurations for filters
function getJobSearchFilters(
  t: any,
  multiple: boolean,
  searchable: boolean,
  includeAllOption: boolean = false
): TFilter[] {
  return [
    {
      label: t('jobBoard.filter.role.label'),
      placeholder: t('jobBoard.filter.role.placeholder'),
      name: 'role',
      multiple,
      searchable,
      itemOptions: getRoleOptions(t, includeAllOption)
    },
    {
      label: t('jobBoard.filter.location.label'),
      name: 'location',
      placeholder: t('jobBoard.filter.location.placeholder'),
      multiple,
      searchable,
      itemOptions: getLocationOptions(t, includeAllOption)
    },
    {
      label: t('jobBoard.filter.positionType.label'),
      name: 'positionType',
      placeholder: t('jobBoard.filter.positionType.placeholder'),
      multiple,
      searchable,
      itemOptions: getPositionTypeOptions(t, includeAllOption)
    },
    {
      label: t('jobBoard.filter.workType.label'),
      name: 'workType',
      placeholder: t('jobBoard.filter.workType.placeholder'),
      multiple,
      searchable,
      itemOptions: getWorkTypeOptions(t, includeAllOption)
    },
    {
      label: t('jobBoard.filter.industry.label'),
      name: 'industry',
      placeholder: t('jobBoard.filter.industry.placeholder'),
      multiple,
      searchable,
      itemOptions: getIndustryOptions(t, includeAllOption)
    }
  ]
}

function getJobSearchFiltersCount() {
  const { fetchOnce } = useAuthFetch()
  return fetchOnce<TJobSearchFiltersCount>(`/api/stats/job-groups/?group=industry`, 'GET')
}

function getSortByOptions(t: any, fields: string[], includeAllOption: boolean = false) {
  const options = fields.map((field: string) => ({
    label: t(`jobBoard.filter.sortedBy.options.${field}`),
    value: field
  }))
  if (includeAllOption) {
    options.unshift({
      label: t('jobBoard.filter.sortedBy.placeholder'),
      value: ''
    })
  }
  return options
}

export type TUseFilters = {
  getJobSearchFilters: typeof getJobSearchFilters
  getWorkTypeOptions: typeof getWorkTypeOptions
  getPositionTypeOptions: typeof getPositionTypeOptions
  getCompanySizeOptions: typeof getCompanySizeOptions
  getIndustryOptions: typeof getIndustryOptions
  getCampaignFilterOptions: typeof getCampaignFilterOptions
  getAppLanguageOptions: typeof getAppLanguageOptions
  getVisaStatusOptions: typeof getVisaStatusOptions
  getLocationOptions: typeof getLocationOptions
  getRoleOptions: typeof getRoleOptions
  getCompanyLeadSourceOptions: typeof getCompanyLeadSourceOptions
  getCompanyActiveStatusOptions: typeof getCompanyActiveStatusOptions
  getCampaignActiveStatusOptions: typeof getCampaignActiveStatusOptions
  getSalaryOptions: typeof getSalaryOptions
  getCurrencyOptions: typeof getCurrencyOptions
  getJobAppDownloadedStatusOptions: typeof getJobAppDownloadedStatusOptions
  getJobAppReadStatusOptions: typeof getJobAppReadStatusOptions
  getJobSearchFiltersCount: typeof getJobSearchFiltersCount
  getYearsOfExperiencesOptions: typeof getYearsOfExperiencesOptions
  getEducationLevelOptions: typeof getEducationLevelOptions
  getISOGeoCodes: typeof getISOGeoCodes
  getJobUserTags: typeof getJobUserTags
  getSortByOptions: typeof getSortByOptions
  getEmploymentStatusOptions: typeof getEmploymentStatusOptions
  getHearUsOptions: typeof getHearUsOptions
}

export function useFilters(): TUseFilters {
  return {
    getJobSearchFilters,
    getWorkTypeOptions,
    getPositionTypeOptions,
    getCompanySizeOptions,
    getIndustryOptions,
    getCampaignFilterOptions,
    getAppLanguageOptions,
    getVisaStatusOptions,
    getLocationOptions,
    getRoleOptions,
    getCompanyLeadSourceOptions,
    getCompanyActiveStatusOptions,
    getCampaignActiveStatusOptions,
    getSalaryOptions,
    getCurrencyOptions,
    getJobAppDownloadedStatusOptions,
    getJobAppReadStatusOptions,
    getJobSearchFiltersCount,
    getYearsOfExperiencesOptions,
    getEducationLevelOptions,
    getISOGeoCodes,
    getJobUserTags,
    getSortByOptions,
    getEmploymentStatusOptions,
    getHearUsOptions
  }
}
